import { prizesRoutesName } from "@/router/names";
import { configProtectionAwardsRoute } from "../guard";

const { INDEX, HISTORY, PRIZES } = prizesRoutesName;

export const prizesRoutes = {
  path: INDEX.path,
  name: INDEX.name,
  ...configProtectionAwardsRoute(),
  redirect: { name: PRIZES.name },
  component: () => import("@/views/public/prizes/Index.vue"),
  children: [
    {
      path: PRIZES.path,
      name: PRIZES.name,
      component: () => import("@/views/public/prizes/prizes/Index.vue"),
    },
    {
      path: HISTORY.path,
      name: HISTORY.name,
      component: () => import("@/views/public/prizes/history/Index.vue"),
    },
  ],
};
