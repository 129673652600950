export const formatDateAndTime = ({ date }, locale = "es-ES") => {
  // Obtiene la zona horaria del dispositivo
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new Intl.DateTimeFormat(locale, {
    timeZone: localTimeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(new Date(date));
};

export const formatDate = ({ date, timeZone }, locale = "es-ES") => {
  return new Intl.DateTimeFormat(locale, {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(date));
};
