import { prizesService } from "@/services";
import { adapterToPrize, adapterToPrizeHistory } from "@/adapters";

export const prizesCtrl = {
  getPrizes: async () => {
    const { data } = await prizesService.getPrizes();
    const { results } = data.data;

    return {
      prizes: results.map((prize) => adapterToPrize(prize)),
    };
  },
  getPrizesHistory: async () => {
    const { data } = await prizesService.getPrizesHistory();
    const { results } = data.data;

    return {
      prizes: results.map((prize) => adapterToPrizeHistory(prize)),
    };
  },
  redeemPrize: async (prizeId) => {
    const { data } = await prizesService.redeemPrize(prizeId);

    return { message: data.message };
  },
};
