import Vue from "vue";
import Router from "vue-router";
import {
  authRoutes,
  checkPaymentRoutes,
  orderRoutes,
  prizesRoutes,
  userRoutes,
} from "./routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        { ...authRoutes },
        { ...prizesRoutes },
        { ...orderRoutes },
        { ...userRoutes },
        { ...checkPaymentRoutes },
        {
          path: "",
          name: "Welcome",
          component: () => import("@/views/public/welcomeHome/Index.vue"),
        },
        {
          path: "/scan-qr",
          name: "ScanQr",
          component: () => import("@/views/public/scanQr/Index.vue"),
        },
        {
          path: ":tableId/menu",
          name: "MenuSec",
          component: () => import("@/views/public/security/Index.vue"),
        },
        {
          path: "/menu",
          name: "Menu",
          component: () => import("@/views/public/menu/Index.vue"),
        },
        {
          path: "/carrito",
          name: "ShoppingCart",
          component: () => import("@/views/public/shopping-cart/Index.vue"),
        },
        {
          path: "/gracias",
          name: "ThankYou",
          component: () => import("@/views/public/thank-you/Index.vue"),
        },
        {
          path: "/legal-concern",
          name: "LegalConcern",
          component: () => import("@/views/public/legal-concern/Index.vue"),
        },
        {
          path: "/privacy-policy",
          name: "PrivacyPolicy",
          component: () => import("@/views/public/privacy-policy/Index.vue"),
        },
        {
          path: "/cookie-policy",
          name: "CookiePolicy",
          component: () => import("@/views/public/cookie-policy/Index.vue"),
        },

        {
          path: "/reserva",
          name: "Bookings",
          component: () => import("@/views/public/reservation/Index.vue"),
        },
        {
          path: "/reserva/gracias",
          name: "Bookings-Thanks",
          component: () => import("@/views/public/thank-you-reserve/Index.vue"),
        },
        {
          path: "*",
          name: "FourOhFour",
          component: () => import("@/views/public/404/Index.vue"),
        },
        {
          path: "",
          name: "LocalDisabled",
          component: () => import("@/views/public/local-disabled/Index.vue"),
        },
      ],
    },
  ],
});

export const navigationRoutes = [
  {
    displayText: "Carta",
    path: "/",
  },
  {
    displayText: "Nosotros",
    path: "/nosotros",
  },
  {
    displayText: "Reserva",
    path: "/reserva",
  },
];

export default router;
