import { userService, addressesService } from "@/services";
import { adapterToUserAddress } from "@/adapters";

export const userCtrl = {
  getUserInfo: async (userId, retrieveSite = false) => {
    const { data } = await userService.getUserInfo(userId, retrieveSite);
    return data;
  },

  getUserAddresses: async () => {
    const { data } = await addressesService.getAddresses();
    if (data.data)
      return data.data.results.map((address) => adapterToUserAddress(address));

    return data;
  },

  handleSaveSettings: async (userInfo) => {
    const { data } = await userService.saveSettings(userInfo);
    return data;
  },

  handleChangePassword: async (payload) => {
    const { data } = await userService.changePassword(payload);
    return data;
  },
};
