import { axiosInstance } from "@/core";
import { addAccessTokenToHeaders, buildUrl } from "@/helpers";

const configWithToken = addAccessTokenToHeaders;

const baseEndpoint = "awards";

export const prizesService = {
  getPrizes: async () =>
    await axiosInstance.get(buildUrl(baseEndpoint), configWithToken()),

  getPrizesHistory: async () =>
    await axiosInstance.post(
      buildUrl(`reports/${baseEndpoint}`),
      {},
      configWithToken()
    ),

  redeemPrize: async (prizeId) =>
    await axiosInstance.post(
      buildUrl(`${baseEndpoint}/exchange`),
      { id: prizeId },
      configWithToken()
    ),
};
