import { axiosInstance } from "@/core/axios";
import { addAccessTokenToHeaders, buildUrl } from "@/helpers";

const config = addAccessTokenToHeaders;

export const userService = {
  getUserInfo: async (userId, retrieveSite = false) => {
    const endpoint = `users/${
      retrieveSite ? "retrieve_site/" + userId : userId
    }`;
    return await axiosInstance.get(buildUrl(endpoint), config());
  },

  saveSettings: async (userInfo) => {
    return await axiosInstance.put(
      buildUrl(`users/${userInfo.id}`),
      userInfo,
      config()
    );
  },

  changePassword: async (payload) =>
    await axiosInstance.post(
      buildUrl(`users/change_password`),
      payload,
      config()
    ),
};
