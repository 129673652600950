export const esPrizesLocale = {
  prizes: "Premios",
  prizesHistory: "Historial de Premios",
  prizesNotFound: "Premios no encontrados 🥺",
  redeem: "Canjear",
  redeemed: "Canjeado",
  redeemPrize: "Canjear Premio",
  redeemPrizes: "Canjear Premios",
  redeemQuestionConfirm: "¿Estás seguro que quieres canjear este premio?",
  redeemConfirm: "Sí, lo quiero",
  yourFooCoins: "Tus Puntos",
  errorGettingUserCoins: "Error al obtener tus Puntos, inténtalo de nuevo...",
  errorGettingUserPrizeHistory:
    "Error al obtener historial de premios, inténtalo de nuevo...",
  goToHistory: "Ver Historial",
};
