export const chPrizesLocale = {
  prizes: "奖品",
  prizesHistory: "奖品历史",
  prizesNotFound: "未找到奖品 🥺",
  redeem: "兑换",
  redeemed: "贖回",
  redeemPrize: "兑换奖品",
  redeemPrizes: "兑换奖品",
  redeemQuestionConfirm: "您确定要兑换这个奖品吗？",
  redeemConfirm: "是的，我想要",
  yourFooCoins: "你的Points",
  errorGettingUserCoins: "未能取得您的 Points，請再試一次...",
  errorGettingUserPrizeHistory: "獲取獎勵記錄出錯，請重試...",
  goToHistory: "檢視歷史",
};
