import { isAuthenticated } from "@/helpers";

export const configProtectionRoute = (requiredUserAuthenticated = true) => ({
  meta: { requiredUserAuthenticated },
  beforeEnter: authGuard,
});

export const authGuard = (to, from, next) => {
  const {
    meta: { requiredUserAuthenticated },
  } = to;
  const isAuthenticatedResult = isAuthenticated();
  return (requiredUserAuthenticated && isAuthenticatedResult) ||
    (!requiredUserAuthenticated && !isAuthenticatedResult)
    ? next()
    : next(from);
};
