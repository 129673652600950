import { getUseAwardsConfig, isAuthenticated } from "@/helpers";

export const configProtectionAwardsRoute = () => ({
  beforeEnter: awardsConfigGuard,
});

export const awardsConfigGuard = async (_to, _from, next) => {
  try {
    const awardsConfig = await getUseAwardsConfig();
    if (!awardsConfig) return next("/menu");
  } catch {
    return next("/menu");
  }
  const isAuthenticatedResult = isAuthenticated();

  return isAuthenticatedResult ? next() : next("/menu");
};
