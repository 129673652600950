export const settingsDigitalLetterStoreGetters = {
  GET_ALLOW_TIPS: "settingsDigitalLetter/GET_ALLOW_TIPS",
  GET_STYLES: "settingsDigitalLetter/GET_STYLES",
  GET_TEXTS: "settingsDigitalLetter/GET_TEXTS",
  GET_IMAGES: "settingsDigitalLetter/GET_IMAGES",
  GET_MINIMAL_ORDER_DOMICILE:
    "settingsDigitalLetter/GET_MINIMAL_ORDER_DOMICILE",
  GET_BOOKINGS: "settingsDigitalLetter/GET_BOOKINGS",
  GET_CALL_WAITER: "settingsDigitalLetter/GET_CALL_WAITER",
  GET_LOCAL_ORDERS: "settingsDigitalLetter/GET_LOCAL_ORDERS",
  GET_USE_AWARDS: "settingsDigitalLetter/GET_USE_AWARDS",
};

export const settingsDigitalLetterStoreMutations = {
  SET_SETTINGS_DIGITAL_LETTER: "SET_SETTINGS_DIGITAL_LETTER",
};

export const settingsDigitalLetterStoreActions = {
  SET_SETTINGS_DIGITAL_LETTER:
    "settingsDigitalLetter/SET_SETTINGS_DIGITAL_LETTER",
};
