export const enPrizesLocale = {
  prizes: "Prizes",
  prizesHistory: "Prizes History",
  prizesNotFound: "Prizes not Found 🥺",
  redeem: "Redeem",
  redeemed: "Redeemed",
  redeemPrize: "Redeem Prize",
  redeemPrizes: "Redeem Prizes",
  redeemQuestionConfirm: "Are you sure you want to redeem this prize?",
  redeemConfirm: "Yes, I do",
  yourFooCoins: "Your Points",
  errorGettingUserCoins: "Error getting your Points, try again...",
  errorGettingUserPrizeHistory:
    "Error getting your prizes history, try again...",
  goToHistory: "Go to history",
};
