import { esAggregateCombinedLocale } from "./aggregatesAndCombined";
import { esAuthLocale } from "./auth";
import { esBtnActionsLocale } from "./btnActions";
import { esCheckPaymentLocale } from "./checkPayment";
import { esErrorBtnLabelLocale, esErrorInputsLocale } from "./errorMessages";
import { esFinishOrder } from "./finishOrder";
import { esHomeLocale } from "./home";
import { esInputsLocale } from "./inputs";
import { esLanguageLocale } from "./languages";
import { esMenuItemLocale } from "./menuItemAppBar";
import { esOrderLocale } from "./order";
import { esOrdersLocale } from "./orders";
import { esReservationLocale } from "./reservation";
import { esTipLocale } from "./tip";
import { esUserAddressLocale } from "./addresses";
import { esUserSettingsLocale } from "./userSettings";
import { esLegalDocuments } from "./legalDocuments";
import { esPrizesLocale } from "./prizes";

export default {
  ...esAggregateCombinedLocale,
  ...esAuthLocale,
  ...esBtnActionsLocale,
  ...esCheckPaymentLocale,
  ...esErrorBtnLabelLocale,
  ...esErrorInputsLocale,
  ...esFinishOrder,
  ...esHomeLocale,
  ...esInputsLocale,
  ...esLanguageLocale,
  ...esMenuItemLocale,
  ...esOrderLocale,
  ...esOrdersLocale,
  ...esPrizesLocale,
  ...esUserAddressLocale,
  ...esTipLocale,
  ...esReservationLocale,
  ...esUserSettingsLocale,
  ...esLegalDocuments,
  lang: "Idioma",
  add: "Añadir",
  products: "Productos",
  productsAdded: "Productos Agregados",
  productAdded: "Producto Agregado",
  waiterCalled: "Se ha llamado al camarero, por favor espere ser atendido",
  problem: "Ups! Algo ha ido mal. Intentalo de nuevo por favor",
  notFound: "Ups! Página no encontrada",
  notFoundText: "Ups! Parece que esta página no existe",
  localDisabledText: "Local desactivado. Por favor, contacte con soporte.",
  menu: "Menu",
  getMeOut: "Sácame de aquí",
  productsNotFound: "¡No se encontraron productos!",
  store: "Tienda",
  myRequest: "Pedido",
  addNote: "¿Deseas añadir alguna nota al pedido?",
  makeRequest: "FINALIZAR PEDIDO",
  emptyCart: "VACIAR CARRITO",
  cartEmpty: "¡Carrito vacío!",
  addProducts: "Añadir productos",
  successRequest: "Pedido creado correctamente",
  scanQrAgain: "Debes volver a escanear un QR",
  thankYou: "¡Muchas Gracias!",
  requestMade: "Tu pedido se ha realizado correctamente.",
  returnToMenu: "Volver a la carta",
  welcome: "¡Bienvenido!",
  scanQr:
    "Navega a través de las opciones del menú o escanea el código QR de la mesa para ver la carta o realizar tu pedido.",
  shoppingCart: "Carrito",
  requestAdded: "Tu pedido ha sido realizado",
  welcomeScan: "Bienvenido, escanea un QR.",
  loading: "Cargando...",
  callWaiter: "Llamar Camarero",
  languageNotAdded:
    "Lo sentimos el sistema todavía no se ha traducido a este idioma",
  legalConcernTitle: "Aviso Legal",
  cookiePolicyTitle: "Política de cookies",
  privacyPolicyTitle: "Política de privacidad",
  outOfStock: "Agotado",
  stockQty: "Cantidad en existencia",
};
