export const adapterToPrize = (prize) => {
  const { id, name, image, coins } = prize;

  return { id, name, image, coins };
};

export const adapterToPrizeHistory = (prize) => {
  const prizeAdapted = adapterToPrize(prize);

  return { ...prizeAdapted, dateRedeemed: prize.created_at };
};
