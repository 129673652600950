<template>
  <section
    :class="$vuetify.breakpoint.lgAndUp ? 'py-10' : ''"
    class="text-center"
  >
    <h3 class="text-center text-body-1 text-lg-h4 font-weight-bold">
      {{ $t(keyTranslate) }}
    </h3>
  </section>
</template>

<script>
export default {
  name: "SectionTitleComponent",
  props: {
    keyTranslate: { type: String, required: true },
  },
};
</script>
