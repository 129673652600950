export const enErrorBtnLabelLocale = {
  retryFetchAction: "Retry",
};

export const enErrorInputsLocale = {
  errorWeakPassword: "The password must have numbers and letters",
  errorEmail: "Enter an email valid",
  error401Unauthorized: "Your email or password provided are invalid",
  errorRequired: "This field is Required",
  errorNotEmoticons:
    "This field does NOT accept special characters or emoticons.",
  errorNotNumber: "This field accepts only letters",
  errorMinLength: "This field must be at least {min} characters",
  errorMaxLength: "This field must have a maximum of {max} characters",
  errorPositiveNumbers: "This field accepts only positive numbers",
  errorRangeNumbers: "This field must be between {min} - {max}",
};
